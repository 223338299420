import { createClient, OperationResult } from 'urql';
import { MarketPlacesResponse } from '@/configs/nft-market';

const API_URL = 'https://api.thegraph.com/subgraphs/name/neco-fun/neco-fun-marketplace';

const client = createClient({
  url: API_URL,
});

export function getMarketplaces()
    : Promise<OperationResult<MarketPlacesResponse, Record<string, any>>> {
  const tokensQuery = `
  query {
    marketplaces(first: 5) {
    id
    publishedItems {
      id
      itemId
      nftId
      nftUri
      amount
      price
    }
    soldItems {
      id
      itemId
      nftId
      nftUri
      amount
      price
    }
    totalPublishedItemAmount
  }
  }
`;
  return client.query(tokensQuery).toPromise();
}

export function test(): Promise<OperationResult<MarketPlacesResponse, Record<string, any>>> {
  const tokensQuery = `
  query {
    marketplaces(first: 5) {
    id
    publishedItems {
      id
      itemId
      nftId
      nftUri
      amount
      price
    }
    soldItems {
      id
      itemId
      nftId
      nftUri
      amount
      price
    }
    totalPublishedItemAmount
  }
  }
`;
  return client.query(tokensQuery).toPromise();
}
