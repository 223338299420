/* eslint no-use-before-define: ["off"] */
export interface MarketplaceItem {
  id: string;
  publishedItems: string;
  soldItems: string;
  totalPublishedItemAmount: string;
}

export interface MarketPlacesResponse {
  data: {
    marketplaces: Array<MarketplaceItem>
  };
  [propName: string]: any;
}

export interface NftItem {
  id: string;
  itemId: string;
  seller: User;
  buyer: User;
  nftId: string;
  nftUri: string;
  amount: string;
  price: string;
  onListTime: string;
  isSoldOut: boolean;
  isOnList: boolean
  fee: string;
  [propName: string]: any;
}

export interface User {
  id: string;
  address: string;
  onListItems?: NftItem
  boughtItems?: NftItem
}

export interface MarketType {
  name: string;
  key: string;
}

/**
 * key: key from the data
 */
export const marketTypesConfig = [
  {
    name: 'market',
    key: 'publishedItems', // key from the data
  },
  {
    name: 'mySell',
    key: 'onListItems',
  },
  {
    name: 'sold',
    key: 'soldItems',
  },
  {
    name: 'history',
    key: 'boughtItems',
  },
];
